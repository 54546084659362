import Vendor from './components/Vendor';
import SmoothScroller from './components/SmoothScroller';
import Polyfills from './components/Polyfills';
import Hamburger from './components/Hamburger';
import JetDetailGalleries from './components/JetDetailGalleries';
import './fontawesome.js';

(function () {
  Polyfills.arrayFrom();
  new Hamburger();
  new JetDetailGalleries(); // auto scroll to hash links, ignoring classes
  // let smoothScroll = new SmoothScroller(150)
  // smoothScroll.scrollOnLoad()
  //
  // statically call 3rd party methods

  Vendor.addUaToBody();
  Vendor.wpImageBlockModals();
  Vendor.wpGalleryBlockModals();
  Vendor.modalPopups(); // Vendor.carousel()
  // Vendor.headroom()
})();