import { dom, library } from '@fortawesome/fontawesome-svg-core'; // import specific icons here to keep the resource load smaller
// TODO: can i do
// import { * as light } from '@fortawesome/pro-light-svg-icons'
// library.add( light.faArrowDown,  ) etc?
// or would that not be as efficient?

import { faArrowRight, faAngleDown, faAngleLeft, faAngleRight, faBars, faTimes, faPlus, faMinus } from '@fortawesome/pro-light-svg-icons';
import { faClock, faHandPointRight } from '@fortawesome/pro-regular-svg-icons';
import { faMapMarkerAlt, faCalendarAlt, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { faFacebookF, faTwitter, faInstagram, faYoutube, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'; // make imported icons available

library.add( // light
faArrowRight, faAngleDown, faAngleLeft, faAngleRight, faPlus, faMinus, faBars, faTimes, // regular
faClock, faHandPointRight, // solid
faMapMarkerAlt, faCalendarAlt, faCaretDown, // brand
faFacebookF, faTwitter, faInstagram, faYoutube, faLinkedinIn); // replace <i>s with <svg>s

dom.watch();